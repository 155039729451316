import { CounterWithMissingDep, CounterWithDeps, DoubleCounter, DoubleCounterWithMemo, DoubleCounterWithStep, FinalDoubleCounter, FinalDoubleCounterWithStep } from "../../../../content/blog/using-callbacks-in-custom-hooks/components.jsx";
import * as React from 'react';
export default {
  CounterWithMissingDep,
  CounterWithDeps,
  DoubleCounter,
  DoubleCounterWithMemo,
  DoubleCounterWithStep,
  FinalDoubleCounter,
  FinalDoubleCounterWithStep,
  React
};