export const LIGHT_THEME = {
  textColor: "#363636",
  iconColor: "#363636",
  ctaText: "#ffffff",
  primaryColor: "#443868",
  tagBackground: "#efeef5",
  toggleIcon: "#000000",
  socialIcons: "#363636",
  themeColor: "#ffffff",
  quoteColor: "#6c6c6c",
  cardBackground: "#ffffff",
  shadowColor: "#dbd9e3",
  mobileMenuBackground: "#443868",
  whiteColor: "#ffffff",
  isDark: false,
}

export const DARK_THEME = {
  textColor: "#ffffff",
  iconColor: "#ffffff",
  ctaText: "#1b1b1b",
  primaryColor: "#a397c7",
  tagBackground: "#1f1f1f",
  toggleIcon: "#f3f3f3",
  socialIcons: "#e7e7e7",
  quoteColor: "#c1c1c1",
  cardBackground: "#2a2a2a",
  shadowColor: "#181818",
  mobileMenuBackground: "#1f1f1f",
  whiteColor: "#ffffff",
  isDark: true,
}
